import { ChangeDetectionStrategy, Component, HostBinding, booleanAttribute, inject, input } from '@angular/core';
import { IonSkeletonText } from '@ionic/angular/standalone';

import { AVATAR_OPTIONS, AvatarSize } from './avatar.options';

const DEFAULT_AVATAR = 'assets/avatar.svg';

/** Avatar component. */
@Component({
	selector: 'wndrc-avatar',
	standalone: true,
	templateUrl: './avatar.component.html',
	styleUrl: './avatar.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IonSkeletonText],
})
export class AvatarComponent {
	private readonly options = inject(AVATAR_OPTIONS);

	/** Whether should show skeleton instead avatar. */
	public readonly skeletonOnly = input(false, { transform: booleanAttribute });

	/** Avatar size. */
	public readonly size = input<AvatarSize>(this.options.size);

	/** Avatar URL. */
	public readonly avatarUrl = input<string, string>(DEFAULT_AVATAR, {
		transform: avatarUrl => avatarUrl !== '' ? avatarUrl : DEFAULT_AVATAR,
	});

	@HostBinding('attr.data-size')
	private get _size(): AvatarSize {
		return this.size();
	}
}
