import { Provider } from '@angular/core';
import { createToken } from '@wndr/common/core/utils/miscellaneous/create-token';
import { provideOptions } from '@wndr/common/core/utils/miscellaneous/provide-options';

/** Avatar size. */
export type AvatarSize = 's' | 'm' | 'l';

/** Avatar options. */
export type AvatarOptions = {

	/** Size. */
	readonly size: AvatarSize;
};

/** Avatar default options. */
export const AVATAR_DEFAULT_OPTIONS: AvatarOptions = { size: 'm' };

/** Injection token for the avatar options. */
export const AVATAR_OPTIONS = createToken(AVATAR_DEFAULT_OPTIONS, 'Avatar options');

/**
	* Provide avatar options.
	* @param options Avatar options.
	*/
export function provideAvatarOptions(options: Partial<AvatarOptions>): Provider {
	return provideOptions(AVATAR_OPTIONS, options, AVATAR_DEFAULT_OPTIONS);
}
